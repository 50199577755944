<template>
    <div class="hearts-container">
      <div class="heart" v-for="heart in hearts" :key="heart.id" :style="heart.style"></div>
    </div>
  </template>

<script>
export default {
  data () {
    return {
      hearts: [], // 存储心形的数组
      heartCount: 30, // 心形数量
      colors: ['#e74c3c', '#f1c40f', '#2ecc71', '#3498db'] // 心形颜色
    }
  },
  mounted () {
    // 初始化生成心形
    this.initHearts()
  },
  methods: {
    // 初始化生成心形
    initHearts () {
      for (let i = 0; i < this.heartCount; i++) {
        // 随机生成心形的大小和颜色
        const size = Math.floor(Math.random() * 50) + 20
        const color = this.colors[Math.floor(Math.random() * this.colors.length)]

        // 随机生成心形的位置
        const x = Math.floor(Math.random() * window.innerWidth)
        const y = Math.floor(Math.random() * window.innerHeight)

        // 随机生成心形的动画速度
        const speed = Math.floor(Math.random() * 6) + 2

        // 将心形添加到数组中
        this.hearts.push({
          id: i,
          style: {
            left: `${x}px`,
            top: `${y}px`,
            width: `${size}px`,
            height: `${size}px`,
            animationDuration: `${speed}s`,
            backgroundColor: color,
            boxShadow: `0px 0px ${size}px ${color}`
          }
        })
      }
    }
  }
}
</script>

  <style scoped>
  .hearts-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }

  .heart {
    position: absolute;
    width: 0;
    height: 0;
    animation-name: heartbeat;
    animation-timing-function: cubic-bezier(0.28, 0.45, 0.06, 1.0);
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-fill-mode: forwards;
  }

  @keyframes heartbeat {
    0% {
      transform: scale(1) rotate(0deg);
    }
    100% {
      transform: scale(1.2) rotate(720deg);
      opacity: 0;
    }
  }

  .heart::before,
  .heart::after {
    content: "";
    position: absolute;
    top: 0;
    left: -50%;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: inherit;
  }

  .heart::after {
    left: 0;
    top: -50%;
  }

  </style>
