<template>
    <div class="bg">
        <div class="sea-world" @click="handleClosePopup">
            <div v-for="fish in fishes" :key="fish.id" :style="fish.style">
                <img src="../assets/hx_shark.png"
                    :style="{ width: fish.size + 'px', transform: fish.imgTransform, transition: `transform ${fish.speed}s linear` }">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SeaWorld',
    props: {
        numFishes: {
            type: Number,
            default: 20
        },
        maxSize: {
            type: Number,
            default: 30
        },
        minSize: {
            type: Number,
            default: 10
        }
    },
    data() {
        return {
            fishes: [],
            timers: []
        };
    },
    created() {
        this.createFishes(this.numFishes);
    },
    methods: {
        createFishes(count) {
            for (let i = 0; i < count; i++) {
                const size = Math.random() * (this.maxSize - this.minSize) + this.minSize * 5;
                const speed = Math.random() * 15 + 5;
                const randomInterval = Math.random() * window.innerWidth + size;
                this.fishes.push({
                    id: i,
                    size,
                    speed,
                    randomInterval,
                    style: {
                        position: 'absolute',
                        top: `${Math.random() * 80 + 10}%`
                    },
                    imgTransform: 'translateX(-' + randomInterval + 'px)'
                });
            }
            this.setFinalTimer();
            
            for (let i = 0; i < count; i++) { 
                this.animateFish(i);
            }
        },
        animateFish(index) {
            const fish = this.fishes[index];
            let endX = window.innerWidth + fish.size;
            let timer = setTimeout(() => {
                fish.imgTransform = `translateX(${endX}px)`;
                let innerTimer = setTimeout(() => {
                    fish.imgTransform = `translateX(${-fish.size}px)`;
                }, fish.speed * 1000);
                this.timers.push(innerTimer);
            }, 100);
            this.timers.push(timer);
        },
        setFinalTimer() {
            const maxSwimTime = Math.max(...this.fishes.map(fish => fish.speed * 2000));
            console.log(maxSwimTime / 1000);
            setTimeout(() => {
                this.handleClosePopup();
            }, maxSwimTime);
        },
        handleClosePopup() {
            this.$emit('close');
            this.$parent.$refs.audio.pause();
            this.$parent.$refs.audio.currentTime = 0;
            this.clearTimers();
        },
        clearTimers() {
            this.timers.forEach(timer => clearTimeout(timer));
            this.timers = [];
        }
    }
};
</script>

<style scoped>
.bg {
    position: fixed;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
}

.sea-world {
    position: relative;
    overflow: hidden;
    height: 100vh;
    width: 100vw;
}

img {
    transition-property: transform;
}
</style>
