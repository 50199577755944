<template>
    <div class="clock">
        {{ currentTime }}
    </div>
</template>

<script>

import { ref } from 'vue'

export default {
  setup () {
    const currentTime = ref(getCurrentTime())

    setInterval(() => {
      currentTime.value = getCurrentTime()
    }, 1000)

    function getCurrentTime () {
      const now = new Date()
      const hour = now.getHours()
      const minute = now.getMinutes()
      return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
    }

    return {
      currentTime
    }
  }
}

</script>

<style>
.clock{
    font-size: 50px;
    font-weight: bold;
    color: black;
}
</style>
