<template>
  <div class="search-box">
    <a>What do you want to do</a>
    <input class="search-input" type="text" placeholder="" v-model="searchQuery" @input="handleInput"
      @keydown.enter="handleKeyDown" />
    <div class="search-result">
      <div class="result-item" v-for="command in filteredCommands" :key="command.command">
        <a>{{ command.command }}</a>
        <a class="description">{{ command.description }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import server from '@/utils/server';

export default {
  data() {
    return {
      commands: [],
      searchQuery: ''
    };
  },
  created() {
    server
      .get('/files/commands.json')
      .then((response) => {
        this.commands = response.data;
        console.log('Loaded JSON:', this.commands);
      })
      .catch((error) => console.error('Error loading JSON:', error));
  },
  computed: {
    filteredCommands() {
      const query = this.searchQuery.toLowerCase().trim();

      if (query === '') {
        return [];
      }

      if (query === 'all') {
        return this.commands;
      }

      return this.commands.filter((command) =>
        command.command.toLowerCase().includes(query)
      );
    }
  },
  methods: {
    handleInput(event) {
      this.searchQuery = event.target.value;
    },
    handleKeyDown(event) {
      if (event.keyCode === 13) {
        this.handleSearch();
      }
    },

    handleSearch() {
      const matchedCommand = this.commands.find(
        (command) => command.command === this.searchQuery
      );

      if (matchedCommand) {
        switch (matchedCommand.type) {
          case 'message':
            this.$emit('popup', 'InformationPopup', {
              message: matchedCommand.content,
            });
            break;

          case 'url':
            window.open(matchedCommand.content, '_blank');
            break;

          default:
            console.log(`未找到匹配的处理器类型: ${matchedCommand.type}`);
        }
      } else {
        console.log('未找到匹配的命令');
      }


      if (this.searchQuery === 'hxshark') {
        this.$emit('popup', 'SeaWorld', {
          numFishes: 20,
          maxSize: 130,
          minSize: 30
        });
      }
    }
  }
};
</script>

<style scoped>
.search-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.search-input {
  width: 50vw;
  line-height: 25px;
  font-size: 20px;
  color: gray;
  border: none;
  outline: none;
  border-radius: 100px;
  padding: 5px;
  caret-color: transparent;
  box-shadow: 0 0 5px #ddd;
  background-color: transparent;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.search-result {
  display: flex;
  flex-direction: column;
  width: 50vw;
  border-radius: 10px;
  box-shadow: 0 0 5px #ddd;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.result-item {
  padding: 5px;
  text-decoration: none;
  font-weight: bold;
  color: gray;
}

.description {
  font-weight: normal;
}
</style>